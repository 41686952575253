<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Выберите" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2> Пользовательский протокол пополнения </h2>
		<p> Настоящее Соглашение о пополнении (далее именуемое « Настоящее Соглашение») заключено между вами и
			платформой UniLive в рамках PIX TECHNOLOGY PTE LTD; Платформа будет предоставлять вам услуги в соответствии с
			правилами эксплуатации, согласованными в настоящем Соглашении и периодически публикуемыми (далее именуемыми «
			Starboy Service» или « Сервис»). </p>
		<p> Для того, чтобы лучше предоставлять услуги пользователям, просим пользователей услуг (т.е. пользователей
			учетных записей, которые делают заказ на покупку "Starboy", далее именуемых "Вы") внимательно прочитать и
			полностью понять настоящее соглашение, прежде чем начать использовать Сервис, в котором условия освобождения
			или ограничения ответственности Платформы, пожалуйста, сосредоточьтесь на чтении. </p>
		<p> Если вы не согласны с настоящим Соглашением, пожалуйста, не выполняйте следующие действия в любой форме
			(включая, но не ограничиваясь этим, нажатие кнопки действия, такие как пополнение, оплата) и не используйте
			Сервис. </p>
		<p>
			Платформа запрещает несовершеннолетним пользоваться сервисом Starboy. Платформа напоминает о том, что если вы
			являетесь опекуном несовершеннолетнего, вы должны взять на себя ответственность за опеку над
			несовершеннолетним, находящимся под вашей опекой, и попросить вас включить режим для несовершеннолетних и /
			или другие средства защиты несовершеннолетних, когда несовершеннолетние используют продукты и услуги,
			связанные с Платформой, контролировать и направлять правильное использование несовершеннолетними продуктов и
			услуг, а также усиливать ограничения и управление онлайн - платежами, чтобы совместно создавать благоприятную
			среду для здорового роста несовершеннолетних.
		</p>
		<h3> i, Содержание службы </h3>
		<p>
			« Звездная фасоль» - это виртуальный инструмент, который платформа предоставляет вам и ограничивается
			соответствующим потреблением внутри платформы. Покупая « Звездные бобы», вы можете использовать и получать
			товары или услуги Платформы в соответствии с инструкциями и инструкциями на соответствующих страницах
			Платформы, включая, но не ограничиваясь этим, услуги по вознаграждению в прямом эфире (пользователи могут
			платить за такие услуги, как трансляция выступлений, предоставляя виртуальные подарки ведущему) и так далее.
			Чтобы избежать сомнений, служба Starboy - это пользовательская плата, платформа продолжает предоставлять
			пользователям общую сетевую технологию и связанные с ней услуги, служба Starboy не является онлайн - платежной
			услугой, « Starboy » не является токенным билетом, виртуальной валютой или ваучером предоплаты, не имеет
			денежной ценности, предоплаты стоимости.
		</p>
		<p> 2 После покупки Starboy вы можете использовать его только для целей, согласованных в соответствии с
			настоящим Соглашением, не выходя за рамки продуктов / услуг, предоставляемых компанией, и не перемещаясь между
			различными учетными записями UniLive. </p>
		<p>
			Вы должны приобрести « Звездные бобы» по официальным каналам, указанным Платформой, и Платформа не признает
			никаких каналов, не уполномоченных Компанией. Если вы используете неофициальные каналы для покупки « Звездных
			бобов», платформа не может гарантировать, что такие « Звездные бобы» могут беспрепятственно войти в ваш
			аккаунт, и такие действия также могут сопровождаться мошенничеством, отмыванием денег и другими рисками, что
			приведет к непоправимым потерям или ущербу для вас, платформы и соответствующих третьих лиц. Таким образом,
			покупка по неофициальным каналам будет считаться нарушением, когда Платформа имеет право вычесть или очистить
			« Звездные бобы» в вашем аккаунте, ограничить функции вашего аккаунта полностью или частично до тех пор, пока
			ваш аккаунт не будет заблокирован навсегда, и вы должны нести ответственность за свои потери, вызванные этим;
			Если нарушение вами вышеуказанного соглашения привело к убыткам Платформы или другой третьей стороны, вы
			несете ответственность за компенсацию. Если вы покупаете через официальные магазины, открытые Платформой на
			сторонних платформах, таких как Skycat, Платформа выдает информацию о пользователе учетной записи через
			назначенную вами учетную запись « Starbow» и / или определяет пользователя услуг по соглашению, заключенному
			между вами и компанией, если таковое имеется.
		</p>
		<p>
			Плата за покупку « Звездных бобов» будет взиматься компанией или назначенным ею партнером. Платформа, в
			частности, предупреждает вас, что соответствующие поставщики услуг из разных каналов покупки могут взимать
			плату за обслуживание канала, когда вы платите, в соответствии с их собственной операционной стратегией, что
			может привести к разнице между суммой платы за покупку одного и того же количества « Звездных бобов» по
			различным каналам или количеством « Звездных бобов», купленных за одинаковую сумму, в зависимости от того,
			какая страница отображается при покупке « Звездных бобов». Пожалуйста, обратите внимание, чтобы подтвердить
			соответствующую информацию на странице и разумно выбрать канал покупки « Звездные бобы».
		</p>
		<p> 5, если вы планируете приобрести « Звездные бобы» для учетной записи другого лица, Платформа выдаст
			соответствующее количество « Звездных бобов» на указанную вами учетную запись, пользователь которой может
			договориться об использовании услуг « Звездные бобы», предоставляемых Платформой в соответствии с настоящим
			Соглашением. В случае возникновения спора, который разрешается по согласованию между вами и пользователем
			учетной записи, Платформа не несет ответственности перед вами и пользователем учетной записи.
		</p>
		<h3> ii, рациональное потребление </h3>
		<p> 1, Платформа выступает за рациональное потребление, соразмерные доходы и расходы. Пожалуйста, убедитесь, что
			вы покупаете и используете услуги Starboy разумно в соответствии с вашими собственными потребительскими
			возможностями и фактическими потребностями, избегая чрезмерного потребления. </p>
		<p>
			Средства, которые вы используете для покупки « Звездных бобов», должны быть доходом, который вы законно
			получили и имеете право использовать; Если вы нарушаете настоящую статью, вы должны урегулировать любой спор
			или спор, возникший в результате этого, и нести ответственность за юридические последствия, а также возместить
			убытки Платформе или третьим лицам в полном объеме. Если Платформа обнаруживает (включая, но не ограничиваясь
			этим, случаи активного обнаружения, получения жалоб от третьих лиц или уведомлений от компетентных органов,
			судебных органов и т.д.), что вы подозреваетесь в нарушении вышеуказанного соглашения, Платформа имеет право
			вычесть или очистить « Звездные бобы» в вашей учетной записи, ограничить все или часть функций вашей учетной
			записи до тех пор, пока ваша учетная запись не будет заблокирована навсегда; В то же время Платформа имеет
			право хранить соответствующую информацию и отчитываться перед соответствующими компетентными органами и
			судебными органами.
		</p>
		<p>
			Платформа строго сопротивляется подстрекательству, стимулированию, подстрекательству пользователей к
			иррациональному вознаграждению, а также склонению и подстрекательству несовершеннолетних к вознаграждению за
			ложную идентификационную информацию. Если вы обнаружите вышеупомянутые нарушения, вы можете сообщить об этом
			Платформе в соответствии с каналом публичности Платформы (в правом нижнем углу прямой трансляции - отчет,
			платформа саморегулирования индустрии прямых трансляций UniLive - отчет и т. Д.), Платформа будет принимать
			меры по удалению в соответствии с законом. Мы надеемся, что вы присоединитесь к нам в создании здоровой и
			упорядоченной экологии сообщества.
		</p>
		<h3> III, Ваши права и обязанности </h3>
		<p> 1 Персональная информация или данные, которые вы предоставляете при использовании Сервиса, должны быть
			правдивыми и точными, а также должны оказывать помощь и содействие в расследованиях надзорных органов (при
			необходимости) в соответствии с требованиями законодательства и Платформы. </p>
		<p>
			2.При покупке « Звездных бобов» вы должны тщательно выбирать и / или вводить информацию о своей учетной записи
			/ почтовый ящик с привязкой к учетной записи, количество покупок « Звездных бобов» и так далее. Если из - за
			вашей собственной ошибки ввода, неправильной работы, недостаточного понимания способа выставления счетов и
			других факторов, вызванных ошибкой учетной записи, неправильным количеством « Звездных бобов» и другими
			ошибками покупки, что приводит к вашей потере или расходам, Платформа имеет право не выплачивать компенсацию
			или компенсацию.
		</p>
		<p> 3 Вы должны хранить и использовать свою учетную запись и нести ответственность за все действия и последствия
			под этой учетной записью. Платформа не несет юридической ответственности за убытки, понесенные Платформой в
			результате невозможности предоставления Услуг или ошибки при предоставлении Услуг в результате: </p>
		<p> (1) Ваша учетная запись недействительна, потеряна, украдена, заблокирована; </p>
		<p> (2) Платежное учреждение третьей стороны, к которому привязана ваша учетная запись, банковский счет
			заморожен, заблокирован или имеет другие отклонения, или вы используете неподтвержденный счет или счет, не
			принадлежащий вам; </p>
		<p> (3) Вы сообщили кому - либо пароль Вашей учетной записи или иным образом разрешили кому - либо войти в Вашу
			учетную запись; </p>
		<p> (4) Другие обстоятельства, при которых у вас есть умышленная или существенная небрежность. </p>
		<p>
			Вы должны использовать Сервис на законных основаниях и не должны использовать Сервис для каких - либо целей,
			которые являются преступными, нарушают общественный порядок, наносят ущерб общественной морали или мешают
			нормальному функционированию Платформы, нарушают законные права и интересы третьих сторон, а также не должны
			использовать Сервис в нарушение каких - либо документов или других требований, которые являются обязательными
			для вас (если таковые имеются). Платформа, в частности, напоминает вам, что вы не должны заимствовать,
			передавать или иным образом предоставлять свою учетную запись другим лицам для использования и разумно
			предотвращать действия других лиц, которые нарушают вышеуказанные соглашения через вашу учетную запись, чтобы
			защитить свою учетную запись и имущество.
		</p>
		<p>
			Платформа предоставляет услуги по возмещению расходов на потребление несовершеннолетних в соответствии с
			законом для защиты законных прав и интересов несовершеннолетних и их опекунов; Вы не должны использовать
			Сервис в незаконных целях или ненадлежащим образом, включая, но не ограничиваясь этим, мошенничество со
			стороны взрослых, которые выдают себя за несовершеннолетнего, чтобы получить возмещение, побуждая
			несовершеннолетнего к потреблению. Вышеупомянутые действия будут представлять собой серьезное нарушение
			настоящего Соглашения, и Платформа имеет право отказаться от возврата средств после разумного подтверждения и
			оставляет за собой право привлечь вас к ответственности в соответствии с законом.
		</p>
		<p>
			Вы обязуетесь не использовать этот исключительный аккаунт для какой - либо незаконной деятельности, такой как
			отмывание денег, и не принимать никаких незаконных требований от других лиц об использовании этого
			исключительного аккаунта для отмывания денег и т.д. Если вы нарушаете вышеуказанное соглашение, Платформа
			имеет право приостановить предоставление услуг, начать расследование и другие меры в соответствии с
			положениями законодательства о борьбе с отмыванием денег и / или требованиями компетентных органов и судебных
			органов, и не несет ответственности за любые убытки или ущерб, понесенные вами в результате этого.
		</p>
		<p>
			Если вы используете Услуги, предоставляемые третьей стороной (например, платежные услуги и т.д.), то в
			дополнение к настоящему Соглашению вы должны согласиться и соблюдать соглашения и соответствующие правила этой
			третьей стороны, и в любом случае споры, возникающие в связи с этой третьей стороной и предоставляемыми ею
			услугами, разрешаются вами самостоятельно и Платформа не несет никакой ответственности перед вами или этой
			третьей стороной.
		</p>
		<h3> IV, Платформа права обязательства </h3>
		<p>
			1. Платформа имеет право периодически устанавливать соответствующие ограничения, напоминания и т.д., в том
			числе, но не ограничиваясь этим, ограничивать лимиты транзакций и / или количество транзакций для всех или
			части пользователей, запрещать использование Сервиса конкретными пользователями или увеличивать проверку
			транзакций на основе поправок к законам и правилам, требований надзорных органов, безопасности торговли,
			обновления операционной стратегии, изменения рыночной среды и т. Д.
		</p>
		<p>
			Для обеспечения безопасности транзакций Платформа также имеет право контролировать ваше использование Услуг, и
			в отношении пользователей или учетных записей, которые, как разумно установлено, являются более рискованными,
			Платформа может принять необходимые меры для предотвращения расширения рисков, обеспечения экологической
			безопасности собственности пользователей и Платформы; Такие необходимые меры включают в себя вычитание или
			очистку « Звездных бобов» в вашем аккаунте, ограничение всех или части функций вашего аккаунта, краткосрочное
			или постоянное блокирование вашего аккаунта и т.д.
		</p>
		<p>
			Когда Платформа обнаруживает ошибку обработки, вызванную системным сбоем или по любой другой причине,
			Платформа имеет право исправить эту ошибку как в пользу Платформы, так и в вашу пользу. На данном этапе, если
			вы фактически получаете меньше « звездных бобов», чем вы должны получить, платформа как можно скорее восполнит
			разницу на свой счет после подтверждения ошибки обработки; Если вы фактически получаете больше « звездных
			бобов», чем вы должны получить, платформа будет иметь право вычитать разницу непосредственно с вашего счета
			без предварительного уведомления.
		</p>
		<p> 4, Платформа имеет право изменять, прерывать, приостанавливать или прекращать Сервис в зависимости от
			конкретных обстоятельств, таких как безопасность транзакций, операционное планирование, национальные законы и
			правила или требования регулирующих органов, и Платформа не несет перед вами никакой ответственности за защиту
			прав или нарушение. </p>
		<h3> v, Условия освобождения </h3>
		<p>
			Вы понимаете и соглашаетесь с тем, что Услуги предоставляются в соответствии с существующими технологиями и
			условиями и что Платформа сделает все возможное для предоставления Вам Услуг и обеспечения их согласованности
			и безопасности. Тем не менее, вы также знаете и признаете, что Платформа не может в любое время или всегда
			предвидеть и предотвращать технические и другие риски, включая, но не ограничиваясь этим, сбои или аномалии в
			обслуживании, которые могут быть вызваны форс - мажорными обстоятельствами, сетевыми причинами, дефектами в
			обслуживании третьих сторон и т. Д. В таких случаях Платформа прилагает почти максимальные коммерческие усилия
			для улучшения, но не несет никакой юридической ответственности перед вами или другими третьими сторонами.
		</p>
		<p> 2 Платформа может самостоятельно осуществлять обслуживание останова, обновление и настройку системы, и если
			это приводит к тому, что вы не можете нормально использовать Сервис, вы соглашаетесь с тем, что Платформа не
			несет юридической ответственности. </p>
		<p> 3 Ни при каких обстоятельствах Платформа не несет никакой косвенной, штрафной, случайной или карательной
			ответственности за ущерб. И вся ответственность Платформы перед вами, независимо от причины или способа,
			никогда не превышает расходы, которые вы платите за использование этого. </p>
		<h3> vi, ответственность за нарушение </h3>
		<p> 1 Если вы нарушаете настоящее Соглашение, Платформа имеет право принять меры управления в отношении вас или
			вашей учетной записи в соответствии с законами и правилами, соответствующими соглашениями и правилами
			управления Платформой, в зависимости от обстоятельств и степени опасности ваших действий, включая, но не
			ограничиваясь этим, предупреждение, вычет или очистку « Звездных бобов» в вашей учетной записи, ограничение
			всех или части функций вашей учетной записи, краткосрочное или постоянное блокирование вашей учетной записи и
			так далее.
		</p>
		<p>
			2. Вы понимаете и соглашаетесь с тем, что в случае, если третья сторона подозревается в использовании вашей
			учетной записи для совершения незаконных действий или нарушений, предусмотренных настоящим Соглашением, в
			целях защиты законных прав и интересов потенциальных жертв и других пользователей, поддержания экологического
			порядка Платформы и предотвращения дальнейшего расширения ущерба, Платформа также имеет право принимать меры
			по управлению вашей учетной записью в соответствии с настоящим Соглашением и соответствующими правилами
			Платформы.
		</p>
		<p>
			Если вы совершаете или подозреваетесь в совершении нарушения или проступка за пределами Платформы, что дает
			Платформе разумные основания полагать, что вы нарушили или намереваетесь нарушить настоящее Соглашение или
			другие правила Платформы, которые могут быть использованы против вас (например, Соглашение об обслуживании
			пользователей UniLive, Конвенция о саморегулировании Сообщества и т.д.
		</p>



	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>